import React from "react";

function SocialButtons() {
  return (
    <div className="space-x-4 mt-5 text-xl min-w-full text-center">
      <a href="https://www.buymeacoffee.com/giuseppe.gangi" target="blank" className="fa fa-coffee inline-block text-white hover:text-gray-600"></a>
      <a href="https://twitter.com/ggangix" target="blank" className="fa fa-twitter inline-block  text-white  hover:text-gray-600"></a>
      <a href="https://github.com/ggangix" target="blank" className="fa fa-github inline-block text-white  hover:text-gray-600"></a>
      <a href="https://www.linkedin.com/in/giuseppegangi/" target="blank" className="fa fa-linkedin inline-block text-white  hover:text-gray-600"></a>
      <a href="https://www.instagram.com/giuseppe.gangi/" target="blank" className="fa fa-instagram inline-block text-white  hover:text-gray-600"></a>

    </div>
  );
}

export default SocialButtons;
