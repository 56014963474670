import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import LanguageSwitcher from "./languageSwitcher";
import { useLanguage } from "../context/LanguageContext";
import { translate } from "../utils/translation";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  const { language } =
    typeof window !== "undefined" ? useLanguage() : { language: "en" };

  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-gray-800 shadow-md">
      <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
        <Link to="/">
          <h1 className="flex items-center text-white no-underline">
            <span className="text-xl font-bold tracking-tight">
              👨‍💻 {site.siteMetadata.title}
            </span>
          </h1>
        </Link>

        <button
          className="items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        {typeof window !== "undefined" && (
          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:flex md:items-center w-full md:w-auto`}
          >
            {[
              {
                route: `/`,
                title: translate("header.home", language),
              },
              {
                route: `/about`,
                title: translate("header.about_me", language),
              },
              {
                route: `/services`,
                title: translate("header.services", language),
              },
              {
                route: `/blog`,
                title: 'Blog',
              },
              {
                route: `/contact`,
                title: translate("header.contact", language),
              },
            ].map((link) => (
              <Link
                className="block mt-4 text-white hover:text-gray-400 hover:underline no-underline md:inline-block md:mt-0 md:ml-6"
                key={link.title}
                to={link.route}
              >
                {link.title}
              </Link>
            ))}
            <div className="mt-4 md:mt-0 md:ml-6">
              <LanguageSwitcher />
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
