// src/utils/translation.js
import en from "../locales/en.json";
import es from "../locales/es.json";

const translations = {
  en,
  es,
};

export const translate = (key, language) => {
  const keys = key.split(".");
  let translation = translations[language];

  keys.forEach((k) => {
    if (translation && translation[k] !== undefined) {
      translation = translation[k];
    } else {
      translation = key; // Fallback to key if translation not found
    }
  });

  return translation;
};
