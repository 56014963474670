import React from "react";
import { useLanguage } from "../context/LanguageContext";

const LanguageSwitcher = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={() => toggleLanguage("en")}
        className={`px-2 py-2 rounded-full focus:outline-none transition-colors ${
          language === "en"
            ? "bg-blue-600 text-white font-semibold"
            : "bg-gray-200 text-gray-700 hover:bg-gray-300"
        }`}
        aria-label="Switch to English"
      >
        🇺🇸 EN
      </button>
      <button
        onClick={() => toggleLanguage("es")}
        className={`px-3 py-2 rounded-full focus:outline-none transition-colors ${
          language === "es"
            ? "bg-blue-600 text-white font-semibold"
            : "bg-gray-200 text-gray-700 hover:bg-gray-300"
        }`}
        aria-label="Switch to Spanish"
      >
        🇪🇸 ES
      </button>
    </div>
  );
};

export default LanguageSwitcher;
