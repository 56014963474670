import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import SocialButtons from "./socialButtons";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900 bg-gray-50">
      <Header />
      <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-8 bg-white rounded-lg">
        {children}
      </main>
      <footer className="bg-gray-800 py-4">
        <div className="flex justify-center">
          <SocialButtons />
        </div>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
